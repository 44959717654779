import { useState } from "react";

function TextAreaInput({ labelTitle, labelStyle, type, containerStyle, defaultValue, placeholder, updateFormValue, updateType, disableInput = false }) {

  const [value, setValue] = useState(defaultValue)

  const updateInputValue = (val) => {
    setValue(val)
    updateFormValue({ updateType, value: val })
  }

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <label className="label">
        <span className={"label-text text-base-content " + labelStyle}>{labelTitle}</span>
      </label>
      <textarea value={value} className="w-full textarea textarea-bordered" placeholder={placeholder || ""} onChange={(e) => updateInputValue(e.target.value)} disabled={disableInput}></textarea>
    </div>
  )
}

export default TextAreaInput;
